import './src/styles/global.css';

import { ActionContextProvider, createConfig } from './src/cra';

import React from 'react';

export const wrapRootElement = ({ element }) => {
  createConfig({
    website: 'Devoted',
  });
  return <ActionContextProvider>{element}</ActionContextProvider>;
};
